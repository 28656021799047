
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Entitytable from "@/components/EntityTable.vue";
import FilterBox from "@/components/FilterBox.vue";
import moment from "moment/moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-subscription-list",
  components: {
    FilterBox,
    Entitytable,
  },
  methods: {
    startLoading() {
      this.loading = true;
    },
    endLoading() {
      this.loading = false;
    },
  },
  mounted() {
    if (
      store.getters.currentUser.moderator ||
      store.getters.hasPermission("callcenters.view")
    ) {
      this.groupingOptions.push({
        name: "Callcenter",
        key: "callcenter",
      });
    }
  },
  data() {
    return {
      loading: false,
      showPercentage: false,
      grouping: 0,
      filters: {
        dateBetween: [
          moment().subtract("1", "month").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        grouping: "date",
      },

      groupingOptions: [
        {
          name: "Date",
          key: "date",
        },
        {
          name: "Offer",
          key: "offer",
        },
        {
          name: "Vertical",
          key: "vertical",
        },
        {
          name: "Country",
          key: "country",
        },
        {
          name: "Month",
          key: "month",
        },
        {
          name: "Hour",
          key: "hour",
        },
        {
          name: "Affiliate",
          key: "affiliate",
        },
        {
          name: "Publisher",
          key: "publisher",
        },
      ],
      columnData: [
        {
          label: "Date",
          name: "date",
        },
        {
          label: "Sales",
          name: "sales",
          class: "w-10",
        },
        {
          label: "Hold",
          name: "hold",
          class: "w-10",
        },
        {
          label: "Rejected",
          name: "rejected",
          class: "w-10",
        },
        {
          label: "Trash",
          name: "trash",
          class: "w-10",
        },
        {
          label: "Total",
          name: "total",
          class: "w-10",
        },
        {
          label: "Revenue",
          name: "revenues",
          class: "w-10",
        },
        {
          label: "Payout",
          name: "payout",
          class: "w-10",
        },
        {
          label: "Approval",
          name: "approval",
          class: "w-10",
        },
        {
          label: "Units",
          name: "units",
          class: "w-10",
        },
      ],
    };
  },

  setup() {
    onMounted(() => {
      setCurrentPageTitle("Statistics");
    });

    return {};
  },
});
